import { Component, Mixins, Watch } from "vue-property-decorator";

import CardsMixin from "../../common/mixins/cards.mixin";
import CardTile from "./components/CardTile/CardTile.vue";

@Component({
  components: {
    CardTile,
    Warnings: () => import("@/components/Warnings/Warnings.vue"),
    IssueCardScreen: () =>
      import(
        "@/features/IssueCard/mobile/components/IssueCardScreen/IssueCardScreen.vue"
      ),
  },
})
export default class MobileCards extends Mixins(CardsMixin) {
  @Watch("isShowIssueCard")
  private onShowIssueCard() {
    window.scrollTo(0, 0);
  }

  private created() {
    this.createdHook();
  }

  private mounted() {
    this.mountedHook();
  }
}
