import { DataTableHeader } from "vuetify";
import { Component, Prop, Mixins } from "vue-property-decorator";
import { Card } from "@/types/card";

import CardMixin from "../../../../common/mixins/card.mixin";

@Component({
  components: {
    CardSetLimitMenu: () => import("../CardSetLimitMenu/CardSetLimitMenu.vue"),
    CardActionsMenu: () => import("../CardActionsMenu/CardActionsMenu.vue"),
    AutoRefillMenu: () => import("../AutoRefillMenu/AutoRefillMenu.vue"),
    ChangeCardBalanceMenu: () =>
      import("../ChangeCardBalanceMenu/ChangeCardBalanceMenu.vue"),
    CardGroup: () => import("../../../../components/CardGroup/CardGroup.vue"),
  },
})
export default class CardTile extends Mixins(CardMixin) {
  @Prop({ type: Object, required: true }) private readonly item!: Card;
  @Prop({ type: Array, required: true })
  private readonly headers!: DataTableHeader[];

  private isEditNote = false;

  private toggleEditNote(state = !this.isEditNote) {
    this.isEditNote = this.canEditNote(this.item) && state;
  }
}
